@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;1,400&display=swap');

* {
	margin: 0;
	padding: 0;
	box-sizing: border-box;
}

body {
	font-family: 'Poppins', sans-serif;
	font-size: 16px;
	background: #f6f6f6;
}

a {
	text-decoration: none;
	color: #000;
}

img {
	max-width: 100%;
}

ul {
	list-style: none;
}

.container {
	width: 100%;
	max-width: 1200px;
	margin: auto;
	padding: 0 20px;
}

.btn {
	background: #007bff;
	color: #fff;
	padding: 10px 20px;
	border: none;
	border-radius: 5px;
	font-size: 1.2rem;
	font-weight: 500;
	cursor: pointer;
	transition: all 0.3s ease-in-out;
}

.btn:hover {
	background: #007bffda;
}

.back-btn {
	margin: 20px 0 0;
}

/* lates arrivals */

.title {
	font-size: 2rem;
}

.products {
	display: grid;
	grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
	grid-gap: 20px;
	margin: 40px 0;
}

.products .product-img {
	border-radius: 10px;
}

.product-content {
	padding: 20px;
	border-radius: 10px;
	background: #fff;
	box-shadow: 0 2px 5px rgba(0, 0, 0, 0.05);
}

.product-content h3 {
	font-size: 1.25rem;
	font-weight: 500;
}

.product-content .price {
	font-size: 1.15rem;
	font-weight: 400;
}

.product-content .btn {
	margin-top: 10px;
	background: #007bff22;
	color: #007bff;
	padding: 10px 20px;
	border-radius: 5px;
	font-size: 1.2rem;
	font-weight: 500;
	cursor: pointer;
	transition: all 0.3s ease-in-out;
	border: 2px solid #007bff;
}

.product-content .btn:hover {
	background: #007bffda;
	color: #fff;
}

.contact-card-section {
	background: #007bff22;
	display: flex;
	justify-content: center;
	flex-direction: column;
	gap: 10px;
	align-items: center;
	margin: 40px 0 0;
	padding: 40px 0;
}

.contact-card-section h2 {
	font-size: 5rem;
	font-weight: 700;
}

.contact-card-section p {
	text-align: center;
	font-size: 1.5rem;
	font-weight: 400;
}

@media (max-width: 992px) {
	.contact-card-section h2 {
		font-size: 3rem;
	}

	.contact-card-section p {
		font-size: 1.2rem;
	}
}

/* shop */

.page-title h2 {
	font-size: 4rem;
	font-weight: 700;
	margin: 20px 0;
	text-transform: capitalize;
}

.page-title span {
	color: #007bff;
}
