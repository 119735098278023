.hero-container {
	width: 100%;
	height: 50vh;
	margin: 50px 0;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	background: #007bff22;
	border-radius: 10px;
}

.hero-container h1 {
	font-size: 8rem;
	font-weight: 700;
	letter-spacing: 2px;
}

.hero-container span {
	color: #007bff;
}

.hero-container h3 {
	font-size: 2rem;
	font-weight: 500;
}

.hero-container small {
	font-size: 1.2rem;
	font-weight: 400;
	margin-bottom: 20px;
}

@media (max-width: 992px) {
	.hero-container h1 {
		font-size: 4rem;
	}

	.hero-container h3 {
		font-size: 1.5rem;
	}

	.hero-container small {
		font-size: 1rem;
	}
}
