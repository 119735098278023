.footer {
	background: rgb(0, 0, 0);
	color: #fff;
	padding: 40px 0;
}

.footer a {
	color: #fff;
}

.footer .footer-flex {
	display: flex;
	justify-content: space-between;
	align-items: center;
}

@media (max-width: 768px) {
	.footer {
		padding: 20px 0;
	}

	.footer .footer-flex {
		flex-direction: column;
	}
}
