.navbar {
	background-color: #f8f9fa;
	box-shadow: 0 2px 5px rgba(0, 0, 0, 0.05);
	width: 100%;
	padding: 10px 0;
}

.navbar-container {
	width: 100%;
	max-width: 1200px;
	margin: auto;
	display: flex;
	justify-content: space-between;
	align-items: center;
}

.navbar-header {
	padding: 0 20px;
}

.logo {
	width: 100px;
}

.navbar-logo span {
	color: #007bff;
	font-size: 250%;
}

.hamburger-menu {
	display: none;
}

.navbar-links {
	display: flex;
	justify-content: space-between;
	align-items: center;
	width: 100%;
	max-width: 1200px;
	margin: auto;
}

.navbar-links li {
	list-style: none;
	padding: 0 20px;
}

.navbar-links li:hover {
	background-color: none;
}

.navbar-links a {
	text-decoration: none;
	color: #000;
	font-size: 1.2rem;
	font-weight: 500;
}

.navbar-links a:hover {
	color: #007bff;
}

.navbar-links a.active {
	color: #007bff;
	font-weight: 600;
}

.cart-mobile {
	display: none;
	cursor: pointer;
}

.cart-desktop {
	display: block;
	cursor: pointer;
}

@media (max-width: 992px) {
	.hamburger-menu {
		display: block;
		padding: 10px;
		color: #007bff;
		cursor: pointer;
		border-radius: 5px;
	}

	.hamburger-menu:hover {
		background-color: #007bff22;
	}

	.hamburger-menu i {
		font-size: 1.5rem;
	}

	.navbar-header {
		display: flex;
		justify-content: space-between;
		align-items: center;
		width: 100%;
	}

	.navbar-container {
		display: block;
	}

	.navbar-links {
		display: block;
	}

	.navbar-links li {
		margin: 10px 0;
		padding: 10px 20px;
	}

	.navbar-links li:hover {
		background-color: #007bff22;
	}

	.flex-icons {
		display: flex;
		gap: 20px;
		align-items: center;
	}

	.cart-mobile {
		display: block;
	}

	.cart-desktop {
		display: none;
	}
}
